<div
  class="op-tab-content--container op-files-tab"
>
  <section
    class="op-tab-content--tab-section"
    [ngClass]="{ 'op-tab-content--tab-section_no-header': (showAttachmentHeader$ | async) === false }"
    data-qa-selector="op-tab-content--tab-section"
  >
    <div
      *ngIf="showAttachmentHeader$ | async"
      class="op-tab-content--header"
    >
      <span class="spot-icon spot-icon_attachment op-files-tab--icon op-files-tab--icon_clip"></span>
      <span class="op-tab-content--header-text" [textContent]="text.attachments.label"></span>
    </div>

    <op-attachments [resource]="workPackage"></op-attachments>
  </section>

  <section
    *ngFor="let storage of storages$ | async"
    data-qa-selector="op-tab-content--tab-section"
    class="op-tab-content--tab-section"
  >
    <op-storage
      [resource]="workPackage"
      [storage]="storage"
      [allowUploading]="storageFileUploadEnabled"
      [allowLinking]="storageFileLinkingEnabled"
    ></op-storage>
  </section>
</div>
